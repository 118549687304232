<template>
  <div class="psd">
    <Header />
    <div class="content">
      <Cards>
        <span slot="title"> {{$t('messages.editPassword')}} </span>
        <div class="from" slot="content">
          <!-- 修改密码表单 -->
          <el-form
            :model="passForm"
            status-icon
            :rules="rules"
            ref="passForm"
            label-width="100px"
            class="demo-passForm"
          >
            <el-form-item :label="$t('messages.originPassword')" prop="oldPass">
              <el-input
                type="password"
                v-model="passForm.oldPass"
                autocomplete="off"
                clearable
                show-password
                @keypress.native.enter="onEnterPress('passForm')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('messages.newPassword')" prop="pass">
              <el-input
                type="password"
                v-model="passForm.pass"
                autocomplete="off"
                show-password
                clearable
                @keypress.native.enter="onEnterPress('passForm')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('messages.passwordAgain')" prop="rePass">
              <el-input
                type="password"
                v-model="passForm.rePass"
                autocomplete="off"
                show-password
                clearable
                @keypress.native.enter="onEnterPress('passForm')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('messages.VerificationCode')" prop="code">
              <span slot="label">
                <span class="fffcolor">{{$t('messages.VerificationCode')}}</span>
              </span>
              <el-input v-model="passForm.code" style="width: 80%" onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
              <el-button style="width: 20%;" :type="codeDisable.type" :disabled="codeDisable.disabled" @click="getCode">{{codeStatus}}</el-button>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="submitForm('passForm')"
                >{{$t('messages.confirmChanges')}}</el-button
              >
              <el-button @click="resetForm('passForm')">{{$t('messages.reset')}}</el-button>
            </el-form-item>
          </el-form>
        </div>
      </Cards>
    </div>
  </div>
</template>

<script>
import Md5 from 'js-md5'
import Cards from "@/components/common/Cards.vue";
import Header from "@/components/homes/header/Header.vue";
import { patchChangePassword, getTelCode } from "@/api/login/regist.js";
export default {
  components: {
    Cards,
    Header,
  },
  data() {
    // 验证原密码
    let validatePass0 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入原密码"));
      } else {
        if (this.passForm.oldPass !== "") {
          this.$refs.passForm.validateField("pass");
        }
        callback();
      }
    };
    // 新密码
    let validatePass1 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        if (this.passForm.pass !== "") {
          this.$refs.passForm.validateField("rePass");
        }
        callback();
      }
    };
    // 验证新密码
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.passForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      passForm: {
        oldPass: "",
        pass: "",
        rePass: "",
        code: ""
      },
      //   验证规则
      rules: {
        oldPass: [{ validator: validatePass0, trigger: "blur" }],
        pass: [{ validator: validatePass1, trigger: "blur" }],
        rePass: [{ validator: validatePass2, trigger: "blur" }],
      },
      codeStatus: '获取',
      codeDisable: {disabled: false, type: "success"},
      nums: 60,
    };
  },
  methods: {
    // 回车触发登录
    onEnterPress(formName) {
      this.submitForm(formName);
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        // 验证成功发送给接口
        if (valid) {
          if (this.passForm.pass !== this.passForm.rePass) {
              this.$message.error(this.$t('messages.twoPasswordsInconsistent'))
              return;
            }
          let res = await patchChangePassword({
            username: localStorage.getItem("user"),
            // oldPassword: Md5(this.passForm.oldPass),
            newPwd: Md5(this.passForm.pass),
            confirmPwd: Md5(this.passForm.rePass),
            code: this.passForm.code,
            type: 1,
            sign: "qcloud_pc_resetPwd_tel_getcode"
          });
          //  成功回调
          if (res.success) {
            this.$message.success("修改成功，请前往重新登录");
            //  重置后返回登录
            this.resetForm(formName);
            setTimeout(() => {
              // 清空本地
              localStorage.clear()
              this.$router.push("/tool/login");
            }, 1000);
          } else {
            //  失败提示
            this.$message.error(res.message);
          }
        } else {
          return false;
        }
      });
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    getCode () {
      const params = {
        tel: localStorage.getItem("user"),
        type: "forgetPwd",
        sign: "qcloud_pc_resetPwd_tel_getcode"
      }
      getTelCode(params).then(res => {
        if (res.success) {
          this.codeDisable.disabled = true
          this.codeDisable.type = "info"
          this.setCode()
          this.$message.success('验证码发送成功')
        } else {
          this.$message.error(res.message)
        }
      })
    },
    setCode () {
      let _that = this
      function doLoop() {
        if (_that.nums > 0) {
          _that.codeStatus = _that.nums + " s"
        } else {
          clearInterval(_that.clock)
          _that.nums = 60
          _that.codeStatus = '获取'
          _that.codeDisable.type = 'success'
          _that.codeDisable.disabled = false
        }
      }
      _that.clock = setInterval(function(){
        _that.nums--
        doLoop()
      },1000)
    },
  },
};
</script>

<style lang="less" scoped>
// 隐藏工具栏
/deep/ .bar {
  position: absolute;
  top: -99999px;
}
.psd {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .content {
    width: 100%;
    flex: 1;
    position: relative;
    .cards {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      height: 90%;
      // 卡片宽高
      /deep/ .box-card {
        width: 100%;
        height: 100%;
        min-width: 800px;
        min-height: 700px;
        .clearfix {
          height: 40px;
          line-height: 40px;
          box-sizing: border-box;
          padding: 0px 15px;
          button {
            height: 100%;
          }
        }
      }
    }
    .from {
      width: 60%;
      max-width: 800px;
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>