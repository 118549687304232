<template>
  <div class="cards">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <!-- 标题 -->
        <slot name="title"></slot>
        <!-- <span>修改密码</span> -->
        <el-button style="float: right; padding: 3px 0" type="text"  @click="toHome"
          >返回首页</el-button
        >
      </div>
      <!-- 内容 -->
      <slot name="content"></slot>
    </el-card>
  </div>
</template>
<script>
export default {
    name:'child',
    methods: {
        toHome() {
            this.$router.push("/home")
        }
    },
};
</script>
<style lang="less" scoped>
.text {
  font-size: 12px;
}
.item {
  margin-bottom: 18px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
/* .clearfix{
    display: flex;
    justify-content: space-between;
} */
.box-card {
  width: 480px;
}
</style>